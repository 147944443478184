<template>
	<div class="person_infor">
		<div class="login_top">
			<div class="img"> <img src="@/assets/login/flag.png"></div>
			<div class="login_title">{{$t('other.myMessage')}}</div>
		</div>
		<div class="all_message">
			<div class="message_item" @click="toMessageDetail(item)" v-for="(item,index) in messageList" :key="index">
				<div class="message_item_top">
					<div class="message_item_left">
						<img :src="item.thumb">
					</div>
					<div class="message_item_right">
						<div class="message_name">{{item.name}}</div>
						<div class="message_time">{{item.new_msg.create_time}}</div>
					</div>
				</div>
				<div class="message_item_bottom">
					{{item.new_msg.title}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				messageList: [],
			}
		},
		mounted() {
			this.getMsgType();
		},
		methods: {
			// 获取分类信息
			getMsgType() {
				this.$http.getMsgType().then(res => {
					if (res.code == 1) {
						this.messageList = res.data;
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			// 去消息详情
			toMessageDetail(item) { 
				if (item.msg_type == 1) {
					this.$router.push('/myMessagePlatform')
				} else {
					this.$router.push('/myMessageSystem')
				}
			}
		}
	}
</script>

<style scoped="" lang="less">
	.person_infor {
		width: 926px;
	}

	.login_top {
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;

		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;
		}
	}

	.all_message {
		padding: 0 24px;
		box-sizing: border-box;

		.message_item {
			padding-top: 32px;

			&:hover {
				cursor: pointer;
			}

			.message_item_top {
				display: flex;

				.message_item_left {
					img {
						width: 44px;
						height: 44px;
					}
				}

				.message_item_right {
					margin-left: 24px;

					.message_name {
						text-align: left;
						font-size: 16px;
						color: #333333;
					}

					.message_time {
						margin: 8px 0 16px;
						color: #BFBFBF;
						font-size: 14px;
					}
				}
			}

			.message_item_bottom {
				padding-bottom: 24px;
				border-bottom: 1px solid #EEEEEE;
				margin-left: 68px;
				color: #666666;
				line-height: 24px;
				font-size: 14px;
				text-align: left;
				word-break: break-all;
			}
		}
	}
</style>
